import React from "react"
import Layout from "../components/layout"
import styled from "styled-components"

const NotFoundPageStyles = styled.section`
  .page {
    padding: 5% 10%;

    .page-title {
      margin-bottom: 4.5rem;
    }

    p,
    ul,
    ol {
      line-height: 2.5rem;
    }
  }
`

const NotFoundPage = () => {
  return (
    <Layout>
      <NotFoundPageStyles>
        <div className="page container container-full">
          <h1 className="page-title">404: Not Found</h1>
          <p>The page you're looking for is not found...</p>
        </div>
      </NotFoundPageStyles>
    </Layout>
  )
}

export default NotFoundPage
